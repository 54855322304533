import React, { useRef, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';


// import { Helmet } from 'react-helmet';

const Hero = (props) => {
    const tagline = props.tagline;
    const title = props.title;
    const description = props.description;
    const video = props.video;
    const halfed = props.halfed;

    return (
        <>
            <div className='hero-section'>
                <video
                    className={halfed && 'video-halfed'}
                    autoPlay muted loop playsInline id="myVideo">
                    <source src={props.video} type="video/mp4" />
                </video>
                <div className={
                    halfed ? 'hero-section-content halfed-hero' : 'hero-section-content'
                } >
                    {
                        tagline ? <span>{tagline}</span> : null
                    }
                    <h1>{title}</h1>
                    <p>{description}</p>
                    <div className='hero-section-buttons'>
                        <Link to='/app/onboarding' className='hero-section-button'>My Campaign</Link>
                        <Link to='/app/onboarding/signup/student/' className='hero-section-button'>Join a Campaign</Link>
                    </div>
                </div>
            </div>



        </>
    );

}

export default Hero;