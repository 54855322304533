
const OurStory = () => {

    return(
        <>
            <div className="storySection">
                <div className="textLeft">
                    <h2 className="storyH">Our Story</h2>
                    <p className="firstP">Founded by Taylor Dillingham of <span className="dlj">Dilly Letter Jackets</span>, SchoolSense was created to make school fundraising simple and effective. After seeing how schools struggle with traditional methods, Taylor set out to design a platform that streamlines donations, engages students, and amplifies campaigns through social media.</p>
                    <p className="secondP">At SchoolSense, we’re here to help schools succeed—because when schools thrive, so do their students.</p>
                </div>
                <div className="rightPics">
                    <div className="leftpics">
                        <img src="/assets/basketball.png" className="topleft" alt=""></img>
                        <img src="/assets/studentcomp.png" className="bottomleft" alt=""></img>
                    </div>
                    <img src="/assets/jerseys.png" className="rightpic" alt=""></img>
                </div>

            </div>
        
        </>
    );

}

export default OurStory;