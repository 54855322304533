import { Link } from "react-router-dom"


export const BackgroundComponent = ({ props }) => {
    return (
        <>
            <div className='launchBackground-page'>
                <img src='/assets/backgroundImg.png' alt='' />
                <div className='backgroundOverlay'>
                    <h2>{props.title}</h2>
                    <p>{props.p}</p>
                    <Link to='/contact-us'><div className='backgroundButton-page'><p>CONTACT US TODAY</p><i className="material-icons">east</i></div></Link>
                </div>
            </div>



        </>
    )
}


export const PersonalizedRow = ({ props }) => {
    return (
        <>
            <div className='personalizedRow'>

                {props.image == 1 ? <img src={'/assets/' + props.imageName} alt='' id='firstLeftImage' /> : null}

                <div className='sideText-page'>
                    <h4>{props.sTitle}</h4>
                    <h2>{props.bTitle}</h2>
                    <p>{props.sTextP}</p>
                    {props.checklist == true ?
                        <div className='checkList'>
                            <div className='checkItem'><i className="material-icons">done</i><h4>{props.check1}</h4></div>
                            <div className='checkItem'><i className="material-icons">done</i><h4>{props.check2}</h4></div>
                            <div className='checkItem'><i className="material-icons">done</i><h4>{props.check3}</h4></div>
                        </div>
                        : null}

                </div>
                {props.image == 2 ? <img src={'/assets/' + props.imageName} alt='' id='secondRightImage' /> : null}
            </div>

        </>
    )
}
