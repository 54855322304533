import React, { useRef, useEffect, useState } from 'react';
import NavBar from '../navbar';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { startAccountDeletion, cancelAccountDeletion } from '../../store/schemas/authSlice';
import Hero from './components/hero';
import TabsSection from './components/schoolTabs';
import Accordion from './components/campaignAccordion';
import OurStory from './components/ourStory';
import Footer from '../footer';
import { PersonalizedRow } from '../howItWorks/pagesComponents';
import { BackgroundComponent } from '../howItWorks/pagesComponents';
import { MetaTagHandler } from '../../../library/client/serverCallHandler';


const Home = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userActive = useSelector(state => state.auth.userActive);
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const pRowData2 = {
        sTitle: "GET YOUR STUDENTS INVOLVED",
        bTitle: "Invite Students & Share Campaign on Socials",
        sTextP: "Invite your students to your campaign. They can personalize their profiles and share your campaign on socials.",
        image: 2,
        imageName: 'invitestudents.png',
        checklist: false,

    }

    useEffect(() => {
        dispatch(cancelAccountDeletion());
    }, [dispatch]);

    const launchData = {
        title: 'Ready To Launch Your Campaign?',
        p: 'Your school fundraising is going places with us.'
    }

    return (
        <>
            <MetaTagHandler >
                {() => (
                    <>
                        <title>
                            SchoolSense Fundraising | Your School’s Fundraising Made Simple
                        </title>
                        <meta
                            name="description"
                            content="Create your personalized campaign page. Invite your students to your campaign. They can personalize their profiles and share your campaign on socials. Fund your school's campaign."
                        />
                        <meta
                            name="keywords"
                            content="SchoolSense Fundraising, fundraising, school fundraising, campaign, students, socials, invite, personalize, profiles, share, campaign, school, fundraising, campaign, students, socials, invite, personalize, profiles, share, campaign"
                        />

                        <meta
                            property="og:image"
                            content="/assets/metas/logo.png"

                        />
                    </>
                )}
            </MetaTagHandler>
            <NavBar />
            <Hero tagline="Get Started Today" title="Your School’s Fundraising Made Simple" description="Create your personalized campaign page. " video="/assets/videos/herobanner.mp4" />

            <div className='personalizedRow-container'>

                <PersonalizedRow props={pRowData2} />
            </div>
            <Accordion />
            <TabsSection />
            <OurStory />

            <BackgroundComponent props={launchData} />
            <Footer />

        </>
    );
};

export default Home;
