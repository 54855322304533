import { useEffect, useState } from "react";

const Accordion = () => {

    const [video, setVideo] = useState("/assets/videos/CollectDonationsEasily.mp4");
    const [windowIsLess, setWindowIsLess] = useState(false);


    const [group, setGroup] = useState(1);
    // const [text, setText]= useState(<p>Support for mastercard, visa, and more. Donations can also be collected via Apple Pay & Google Pay. </p>);

    useEffect(() => {
        if (typeof window !== "undefined") {
            const handleResize = () => {
                if (window.innerWidth < 1130) {
                    setWindowIsLess(true);
                } else {
                    setWindowIsLess(false);
                }
            };

            handleResize(); // Initial check

            window.addEventListener("resize", handleResize); // Listen for window resize
            return () => window.removeEventListener("resize", handleResize); // Cleanup
        }
    }, []);

    const group1 = () => {
        setGroup(1);
        setVideo("/assets/videos/CollectDonationsEasily.mp4")

    }
    const group2 = () => {
        setGroup(2);

        setVideo("/assets/videos/ShareCampaign.mp4")

    }
    const group3 = () => {
        setGroup(3)

        setVideo("/assets/videos/InviteStudents.mp4")

    }
    const group4 = () => {
        setGroup(4);

        setVideo("/assets/videos/BuiltInCompetition.mp4")
    }



    return (


        <>


            <div className="accordionSection">
                <div className="accordion-video">
                    <video key={video} autoPlay muted loop playsInline ><source src={video} type="video/mp4"></source>Video Not Supported</video>
                </div>

                <div className="textColumn">
                    <h2 className="campaignTitle">Create a Campaign For Your School</h2>

                    <div className={group == 1 ? "boxOpen" : "boxClosed"} onClick={group1}>
                        {
                            windowIsLess ? <video className="box-video" autoPlay muted loop playsInline >
                                <source src="/assets/videos/CollectDonationsEasily.mp4" type="video/mp4" />
                            </video> : null
                        }
                        <div>
                            <i className="material-icons frontIcon" id="specialIcon">done</i>
                            <div className="boxText">
                                <h3>Collect Donations Easily & Securely</h3>
                                <p className="boxP" style={{ display: group == 1 ? 'inline' : 'none' }}>Support for mastercard, visa, and more. Donations can also be collected via Apple Pay & Google Pay. </p>

                            </div>
                            <i className="material-icons arrow">{group == 1 ? 'expand_less' : 'expand_more'}</i>
                        </div>
                    </div>
                    <div className={group == 2 ? "boxOpen" : "boxClosed"} onClick={group2}>
                        {
                            windowIsLess ? <video className="box-video" autoPlay muted loop playsInline >
                                <source src="/assets/videos/ShareCampaign.mp4" type="video/mp4" />
                            </video> : null
                        }
                        <div>
                            <i className="material-icons frontIcon">share</i>
                            <div className="boxText">
                                <h3>Share You’re Campaign on Social Media</h3>
                                <p className="boxP" style={{ display: group == 2 ? 'inline' : 'none' }}>
                                    Campaigns can be shared on Facebook, Twitter, and more. Donors can also share the campaign with their friends & family.
                                </p>

                            </div>

                            <i className="material-icons arrow">{group == 2 ? 'expand_less' : 'expand_more'}</i>
                        </div>

                    </div>
                    <div className={group == 3 ? "boxOpen" : "boxClosed"} onClick={group3}>
                        {
                            windowIsLess ? <video className="box-video" autoPlay muted loop playsInline >
                                <source src="/assets/videos/InviteStudents.mp4" type="video/mp4" />
                            </video> : null
                        }
                        <div>
                            <img id="specialImg" src="/assets/graduationIcon.png" alt="customIcon" className="customImg" />
                            <div className="boxText">
                                <h3>Invite Students To Personalize Their Profile</h3>
                                <p className="boxP" style={{ display: group == 3 ? 'inline' : 'none' }}>
                                    Students can personalize their profile with a photo, a bio, and more. They can also track their progress and see donations.
                                </p>
                            </div>
                            <i className="material-icons arrow">{group == 3 ? 'expand_less' : 'expand_more'}</i>
                        </div>
                    </div>
                    <div className={group == 4 ? "boxOpen" : "boxClosed"} onClick={group4}>
                        {
                            windowIsLess ? <video className="box-video" autoPlay muted loop playsInline >
                                <source src="/assets/videos/BuiltInCompetition.mp4" type="video/mp4" />
                            </video> : null
                        }
                        <div>
                            <i className="material-icons frontIcon">leaderboard</i>
                            <div className="boxText">
                                <h3>Built in Competitions For Students</h3>
                                {/*  {group == 4 ? text: null} */}
                                {/*  {{group == 4 ? 'display:inline;' : 'display:none;'}} */}
                                <p className="boxP" style={{ display: group == 4 ? 'inline' : 'none' }}>
                                    Prizes can be awarded to the top fundraisers. Theres also built in leaderboards to track progress.
                                </p>
                            </div>
                            <i className="material-icons arrow">{group == 4 ? 'expand_less' : 'expand_more'}</i>
                        </div>
                    </div>

                </div>


            </div>




        </>
    );
}
export default Accordion;

